@media only screen and (max-width: 600px) {
  #fc_frame,
  #fc_frame.fc-widget-normal {
    bottom: 56px !important;
  }
}

.chat-fc-form-outer {
  border-radius: 10px !important;
}

.chat-fc-form-outer .fc-header {
  padding: 18px 15px !important;
}

.chat-fc-form-outer div.fc-form a.fc-button {
  border-radius: 6px !important;
  text-decoration: none !important;
  padding: 10px 0 !important;
}

.chat-fc-form-outer div.fc-form ul li {
  margin-bottom: 23px !important;
}
